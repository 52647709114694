import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import { Flowbite } from 'flowbite-react';
import AOS from 'aos';

import ContextProvider from '../Components/Context/ContextProvider';
import { useAppContext } from '../lib/context';

import WrapperAudit from '../Components/Pages/Audit/Wrapper.js';
import Audit from '../Components/Pages/Audit/Audit.js';
// import About from '../Components/Pages/About/About';
// import Works from '../Components/Pages/Works/Works';
// import Work from '../Components/Pages/Works/Work';
import Comp404 from '../Components/Pages/404';
// import Contact from '../Components/Pages/Contact/Contact';

import 'aos/dist/aos.css';
import '../assets/css/index.css';
import ScrollToTop from '../Components/Shared/ScrollToTop';

// import Offers from '../Components/Pages/Offers/Offers';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
  }, []);

  const [app] = useAppContext();

  React.useEffect(() => {
    if (app.data?.contact.email)
      // @ts-ignore
      $crisp.push(['set', 'user:email', app.data.contact.email]);
  }, [app.data?.contact?.email]);

  return (
    <React.StrictMode>
      <HelmetProvider>
        <Flowbite
          theme={{
            theme: {
              alert: {
                color: {
                  primary: 'bg-primary',
                },
              },
            },
          }}
        >
          <ContextProvider>
            <BrowserRouter>
              <ScrollToTop>
                <Routes>
                  <Route path="/audits" element={<WrapperAudit />}>
                    <Route path=":id" element={<Audit />} />
                    {/* <Route path="about" element={<About />} />
                    <Route path="offers" element={<Offers />} />
                    <Route path="works" element={<Works />} />
                    <Route path="works/:id/" element={<Work />} />
                    <Route path="contact" element={<Contact />} /> */}
                    {/*
              } />
              <Route path="blogs" element={<BlogTwo />} />
              <Route path="works" element={<PortfiloTwo />} /> */}
                  </Route>
                  <Route path="*" element={<Comp404 />} />
                </Routes>
              </ScrollToTop>
            </BrowserRouter>
          </ContextProvider>
        </Flowbite>
      </HelmetProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
);
