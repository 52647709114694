import { useCallback } from 'react';
import { useAtom, atom } from 'jotai';
import 'firebase/compat/firestore';
import { query, where, collectionGroup, getDocs } from 'firebase/firestore';
import { Collections } from '@webfuze/shared';
import { firestore } from './firebase';

import { AppContext } from '../types';

// ********************* App Context ********************* //
const { Types: AppTypes } = AppContext;
type AppState = AppContext.State;
type AppActions = AppContext.Actions;

const appInitialValues = {
  loading: false,
  calendlyIsOpen: false,
} as AppState;

const AppContextAtom = atom(appInitialValues);

const useAppContext = () => {
  const [state, setState] = useAtom(AppContextAtom);

  const dispatch = useCallback<React.Dispatch<AppActions>>(
    (action) => {
      switch (action.type) {
        case AppTypes.SetSetting:
          setState((prev) => ({ ...prev, ...action.payload }));
          break;
        case AppTypes.GetSetting:
          setState((e) => ({
            ...e,
            loading: true,
          }));

          (async () => {
            const auditQuery = query(
              collectionGroup(firestore, 'landings'),
              where('id', '==', action.payload),
            );

            await getDocs(auditQuery)
              .then((e) => {
                const data = e.docs[0].data() as Collections.Landing.Full;
                setState({
                  ...appInitialValues,
                  data,
                });
              })
              .catch((error) => {
                setState({
                  ...appInitialValues,
                  error,
                } as AppState);
                console.log(error, 'error');
              });
          })();

          break;
        case AppTypes.ResetSetting:
          setState({ ...appInitialValues, loading: false });
          break;

        case AppTypes.SetCalendlyIsOpen:
          setState((e) => ({ ...e, calendlyIsOpen: action.payload }));
          break;

        default:
          break;
      }
    },
    [setState],
  );

  return [state, dispatch] as [AppState, React.Dispatch<AppActions>];
};

export { AppContextAtom, useAppContext, AppTypes };
export type { AppState };

// ********************* Modal Context ********************* //
const ModalAtom = atom<
  { isOpen: boolean; children: React.ReactNode; title: string; data: any },
  { isOpen: boolean; children?: React.ReactNode; title?: string; data?: any }
>(
  { isOpen: false, children: '', title: '', data: null },
  (get, set, { isOpen, children, title, data }) => {
    set(ModalAtom, {
      isOpen,
      children: children || '',
      title: title || '',
      data: data || null,
    });
  },
);

export { ModalAtom };
