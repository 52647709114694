import React from 'react';
import { uniqBy } from 'lodash-es';

import { useAppContext } from '../../../lib/context';
import useLanguage from '../../../hooks/useLanguage';

const SliderTechnologies = () => {
  const [app] = useAppContext();

  const formatMessage = useLanguage();

  return (
    <div className="-mt-2 dark:bg-[#0D0D0D] max-w-full h-auto rounded-xl">
      <h2 className="text-3xl font-bold">
        {formatMessage('info.yourTechnologies')}
      </h2>

      <div
        className="mt-2 grid auto-rows-auto auto-cols-max"
        style={{
          gridTemplateColumns: 'repeat(auto-fill,minmax(120px,1fr))',
          gridColumnGap: '1rem',
          gridRowGap: '1rem',
          textAlign: 'center',
        }}
      >
        {uniqBy(app.data.audit.technologies, 'id')
          .filter((e) => e)
          .map((item, i) => (
            <div
              key={i}
              className="flex flex-col bg-[#F8FBFB] py-3 px-1 justify-between rounded-xl hover:text-orange transition duration-200 ease-in-out transform lg:hover:scale-[120%]"
            >
              <img
                key={i}
                className="h-[35px] w-auto mx-auto block mb-3"
                src={`/technologies/${item.icon}`}
                alt={item.name}
              />
              <span className="text-xs block">{item.name}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SliderTechnologies;
