/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';

import { MdOutlineSchool } from 'react-icons/md';
import { FaBlogger, FaBuilding } from 'react-icons/fa';
import { RiContactsBookLine } from 'react-icons/ri';
import { IoPricetagsOutline } from 'react-icons/io5';
import { CgNotes } from 'react-icons/cg';

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem('theme'));
  const [singleData, setSingleData] = useState<{
    id: string;
    tag?: string;
    title?: string;
    bg?: string;
    client?: string;
    langages?: string;
    link?: string;
    linkText?: string;
    description?: string;
  }>();
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem('theme');

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem('theme', 'light');
    } else {
      themeValue === 'dark' && setCheck(true);
      themeValue === 'light' && setCheck(false);
    }

    localStorage?.getItem('theme') === 'dark'
      ? document.documentElement.classList.add('dark')
      : document.documentElement.classList.remove('dark');
  }, []);

  // Create and light theme function
  const handleTheme = (value: string) => {
    if (value === 'light') {
      setCheck(false);
      localStorage.setItem('theme', 'light');
      setLocal('light');
    } else {
      setCheck(true);
      localStorage.setItem('theme', 'dark');
      setLocal('dark');
    }
    localStorage?.getItem('theme') === 'dark'
      ? document.documentElement.classList.add('dark')
      : document.documentElement.classList.remove('dark');
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }: LinkProps & { inactiveClassName?: string; activeClassName?: string }) {
    const location = useLocation();
    const isActive = location.pathname === to;
    const allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);

    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: '1',
      tag: 'UI/UX',
      title: 'Chul urina',
      //   img: work1,
      //   imgSmall: workSmall1,
      bg: '#FFF0F0',
      client: 'Envato',
      langages: 'Photoshop, Figma',
      link: 'https://www.envato.com',
      linkText: 'www.envato.com',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia placeat magnam possimus iusto blanditiis pariatur labore explicabo quo repellat hic dolorum numquam asperiores, voluptatum fugiat reiciendis aspernatur, non, odio aperiam voluptas ex tempora vitae. Dolor, consequatur quidem! Quas magni distinctio dolorum dolore natus, vel numquam accusamus. Nostrum eligendi recusandae qui tempore deserunt!',
    },
  ];

  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: '1',
      //   img: blog1,
      //   imgSmall: blogSmall1,
      date: '177 April',
      category: 'Inspiration',
      title: 'How to Own Your Audience by Creating an Email List.',
      bg: '#FCF4FF',
      description:
        'Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ',
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: '01',
      name: 'Mon audit',
      link: '/audit',
    },
    {
      id: '03',
      name: 'A propos',
      link: '/about',
    },
    {
      id: '04',
      name: 'Projets',
      link: '/works',
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: '01',
      name: 'Audit',
      link: '/audit',
      icon: <CgNotes />,
    },
    {
      id: '02',
      name: 'Offres',
      link: '/offers',
      icon: <IoPricetagsOutline />,
    },
    {
      id: '03',
      name: 'Société',
      link: '/about',
      icon: <FaBuilding />,
    },
    {
      id: '04',
      name: 'Projets',
      link: '/works',
      icon: <CgNotes />,
    },
    {
      id: '05',
      name: 'Blog',
      link: '/home/blogs',
      icon: <FaBlogger />,
    },
    // {
    //   id: '05',
    //   name: 'Contact',
    //   link: '/contact',
    //   icon: <RiContactsBookLine />,
    // },
  ];

  // Slider image for Clients
  const sliderImg = [] as string[];

  // experience items for about page
  const experienceArray = [
    {
      id: '1',
      //   icon,
      title: 'Ui/Ux Design',
      des: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.',
      color: '#D566FF',
      bg: '#FCF4FF',
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: 'Education',
      icon: MdOutlineSchool,
      id: '01',
      date: '2021-2023',
      title: 'Ph.D in Horriblensess ',
      place: 'ABC University, Los Angeles, CA',
      bg: '#FFF4F4',

      id1: '02',
      date1: '2019 - Present',
      title1: 'Sr. Software Tester',
      place1: 'Google Inc.',
      bg1: '#FFF1FB',

      id2: '03',
      date2: '2021',
      title2: 'Best Developer ',
      place2: 'University Of Melbourne, NA',
      bg2: '#FFF4F4',
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: '01',
      color: '#FF6464',
      name: 'Web Design',
      number: '80',
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: '01',
      //   icon: iconPhone,
      title: 'Phone ',
      item1: '+452 666 386',
      item2: '+452 666 386',
      bg: '#FCF4FF',
    },
  ];

  // fillter portfilo data
  const handleData = (text: string) => {
    if (text === 'All') {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id: string) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id: string) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
