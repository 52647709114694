import React from 'react';
import Slider from 'react-slick';

import useLanguage from '../../hooks/useLanguage';

const SliderCommon = () => {
  const formatMessage = useLanguage();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1535,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <h3 className="text-center dark:text-white text-xl lg:text-2xl font-semibold mb-6 mt-8">
        {formatMessage('info.ourCustomersReviews')}
      </h3>
      <div
        data-aos="fade"
        className="bg-[#F8FBFB] dark:bg-[#0D0D0D] max-w-full h-auto py-3 rounded-xl"
      >
        <Slider {...settings}>
          <div className="bg-white dark:bg-gray-800 shadow-lg mx-auto rounded-xl p-4">
            <p className="text-gray-600 dark:text-white">
              <span className="font-bold text-orange text-lg">“</span>
              {formatMessage('review.one')}
              <span className="font-bold text-orange text-lg">”</span>
            </p>
            <div className="flex items-center mt-4">
              <a href="#" className="block relative">
                <img
                  alt="profil"
                  src="/images/reviews/lolo-avatar.png"
                  className="mx-auto object-cover rounded-full h-10 w-10 "
                />
              </a>
              <div className="flex flex-col ml-2 justify-between">
                <span className="font-semibold text-orange text-sm">
                  Laurent Thomas
                </span>
                <span className="dark:text-gray-400 text-xs flex items-center">
                  CEO Bobby
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-800 shadow-lg mx-auto rounded-xl p-4">
            <p className="text-gray-600 dark:text-white">
              <span className="font-bold text-orange text-lg">“</span>
              {formatMessage('review.two')}
              <span className="font-bold text-orange text-lg">”</span>
            </p>
            <div className="flex items-center mt-4">
              <a href="#" className="block relative">
                <img
                  alt="profil"
                  src="/images/reviews/dupuy.jpg"
                  className="mx-auto object-cover rounded-full h-10 w-10 "
                />
              </a>
              <div className="flex flex-col ml-2 justify-between">
                <span className="font-semibold text-orange text-sm">
                  Philippe Dupuy
                </span>
                <span className="dark:text-gray-400 text-xs flex items-center">
                  CEO Lettershop
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-800 shadow-lg mx-auto rounded-xl p-4">
            <p className="text-gray-600 dark:text-white">
              <span className="font-bold text-orange text-lg">“</span>
              {formatMessage('review.three')}
              <span className="font-bold text-orange text-lg">”</span>
            </p>
            <div className="flex items-center mt-4">
              <a href="#" className="block relative">
                <img
                  alt="profil"
                  src="/images/reviews/azoulay.jpg"
                  className="mx-auto object-cover rounded-full h-10 w-10 "
                />
              </a>
              <div className="flex flex-col ml-2 justify-between">
                <span className="font-semibold text-orange text-sm">
                  David Azoulay
                </span>
                <span className="dark:text-gray-400 text-xs flex items-center">
                  CEO meublerDesign
                </span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default SliderCommon;
