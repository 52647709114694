import React from 'react';
import { useAtom } from 'jotai';
import YouTube, { YouTubePlayer } from 'react-youtube';

import { ModalAtom } from '../../lib/context';
import './Modal/Modal.css';

export default function YoutubeVideoModal() {
  const [{ isOpen, title }, setModal] = useAtom(ModalAtom);
  const [player, setPlayer] = React.useState<YouTubePlayer>();

  React.useEffect(() => {
    if (player && isOpen) {
      player?.seekTo(0);
      player?.playVideo();
    } else {
      player?.pauseVideo();
    }
  }, [isOpen]);

  return (
    <div
      className={`${title} modalCustom-overlay ${
        isOpen && title === 'youtube' ? '' : 'hidden'
      }`}
    >
      <div
        onClick={() => setModal({ isOpen: false })}
        className="modalCustom-close-overlay"
      ></div>
      <div className="modalCustom-popup">
        <div className="modalCustom-popup-content">
          <YouTube
            className="youtube wefuze-youtube"
            onReady={(event) => {
              setPlayer(event.target);
            }}
            onEnd={() => setModal({ isOpen: false })}
            videoId="NWxldzqtSds"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                autoplay: 0,
                // loop: 1,
                modestbranding: 1,
                rel: 0,
                iv_load_policy: 3,
                hl: 'fr-fr',
                fs: 0,
                color: 'white',
                controls: 0,
                disablekb: 1,
                playlist: 'NWxldzqtSds',
                showinfo: 0,
              },
            }}
          />
        </div>
      </div>
      <button
        className="modalCustom-popup-close"
        onClick={() => setModal({ isOpen: false })}
        aria-label="Close modal"
        style={{
          display: 'block',
          border: 'none',
          padding: 0,
        }}
      ></button>
    </div>
  );
}
