import { useLocation } from 'react-router-dom';

import DisplayOnScroll from '../Shared/DisplayOnScroll';
import { useAppContext } from '../../lib/context';
import useLanguage from '../../hooks/useLanguage';

export default function LeftColumnContact() {
  const [app] = useAppContext();
  const a = useLocation();
  const formatMessage = useLanguage();

  const text = (
    <p className="text-left mb-6 text-blue font-NeueMachina text-md">
      {`${formatMessage('info.hello')}${
        app.data.contact.firstname ? ` ${app.data.contact.firstname}` : ''
      },`}
      <span className="h-[12px] block"></span>
      {formatMessage('info.audit.message')}
      <br />
      <b>{app.data.audit.website}</b>
      <span className="h-[12px] block"></span>
      {formatMessage('info.audit.end')}
    </p>
  );

  return <>{text}</>;

  return a.pathname === '/' ? (
    <DisplayOnScroll action="hide" breakpoint={213}>
      {text}
    </DisplayOnScroll>
  ) : (
    <></>
  );
}
