import React from 'react';
import { Tooltip } from 'flowbite-react';
// import ReactGA from 'react-ga4';
import {
  FaEnvelope,
  FaGlobe,
  FaLinkedinIn,
  FaPhoneAlt,
  FaCalendarAlt,
} from 'react-icons/fa';

import { useAppContext, AppTypes } from '../../lib/context';
import Button from '../Shared/Button';
import config from '../../lib/config.json';

export default function EmployeeCard(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) {
  const [app, dispatch] = useAppContext();

  React.useEffect(() => {
    setTimeout(() => {
      const video = document.getElementById('videoEmployee') as any;

      video?.addEventListener(
        'ended',
        () => {
          document
            .getElementById('videoEmployee')
            ?.setAttribute('hidden', 'true');
          document.getElementById('imgEmployee')?.removeAttribute('hidden');
        },
        false,
      );
    }, 1000);
  }, []);

  const startVideo = () => {
    const video = document.getElementById('videoEmployee') as any;

    if (video) {
      video.play();
      //   ReactGA.event('play_video', {
      //     video_id: video.id,
      //     start_video: true,
      //     end_video: false,
      //     title: `Video employee ${app.data.employee.firstname} ${app.data.employee.lastname}`,
      //   });

      document.getElementById('videoEmployee')?.removeAttribute('hidden');
      document.getElementById('imgEmployee')?.setAttribute('hidden', 'true');
    }
  };

  return (
    <div {...props}>
      <div
        className={`relative w-[240px] mx-auto h-[240px] drop-shadow-xl rounded-[20px] ${
          app.data.user.videoUrl ? 'cursor-pointer' : ''
        }`}
        id="imgEmployee"
        onClick={() => startVideo()}
      >
        <img src={app.data.user.avatarUrl} className="rounded-[20px]" />
        {app.data.user.videoUrl && (
          <button className="absolute left-3 top-3 play-btn"></button>
        )}
      </div>
      {app.data.user.videoUrl && (
        <video
          id="videoEmployee"
          hidden={true}
          className="w-[240px] mx-auto h-[240px] drop-shadow-xl rounded-[20px]"
        >
          <source
            data-v-00252b6b=""
            src={app.data.user.videoUrl}
            type="video/mp4"
          ></source>
        </video>
      )}
      {/* Social card */}
      <div className="">
        <h1 className="mt-4 text-2xl dark:text-white text-blue">
          {`${app.data.user.firstname} ${app.data.user.lastname}`}
        </h1>
        <h3 className="text-blue inline-block dark:bg-[#1D1D1D] px-5 rounded-lg dark:text-[#A6A6A6]">
          {app.data.user.job}
        </h3>

        {/* Social Links */}
        <div className="flex justify-center gap-2 mt-1">
          {/* tel link add here */}
          <Tooltip content={app.data.user.phone} style="auto">
            <a
              href={`tel:${app.data.user.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-green">
                <FaPhoneAlt />
              </span>
            </a>
          </Tooltip>

          {/* linkedin link add here */}
          <a
            href={app.data.user.linkedin.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>

          {/* email link add here */}
          <Tooltip content={app.data.user.email} style="auto">
            <a
              href={`mailto:${app.data.user.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#000]">
                <FaEnvelope />
              </span>
            </a>
          </Tooltip>

          <Tooltip content={config.website} style="auto">
            <a
              href={`${config.website}?utm_source=audit&utm_medium=web&utm_campaign=employee`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-orange">
                <FaGlobe />
              </span>
            </a>
          </Tooltip>
        </div>
        <Button
          className={`flex items-center mx-auto mt-6 lg:px-4 ${
            app.data.audit.valid ? '' : 'hidden'
          }`}
          name="leftColumnEmployee"
          onClick={() =>
            dispatch({
              type: AppTypes.SetCalendlyIsOpen,
              payload: true,
            })
          }
          children={
            <>
              <FaCalendarAlt />
              <span className="ml-2 text-sm hover:underline">
                Planifier une démo
              </span>
            </>
          }
        />
      </div>
    </div>
  );
}
