// ScrollToTop.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', // Optional if you want to skip the scrolling animation
    });
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
