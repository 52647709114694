import React from 'react';
import { MdOutlineOpenInFull, MdOutlineCloseFullscreen } from 'react-icons/md';
import { BsCheck2Circle, BsCheckCircleFill } from 'react-icons/bs';
import { useSetAtom } from 'jotai';
import CircleMain from './Cirlcle';
import Visu from './Visu';

import useLanguage, { LanguageKeys } from '../../../hooks/useLanguage';
import { useAppContext, AppTypes, ModalAtom } from '../../../lib/context';

import WrapperPage from '../WrapperPage';
import logoGoogle from '../../../assets/images/logo/google-logo.jpg';
import astroAnimFlag from '../../../assets/images/icons/astronaut-rocket-animation.svg';
import LogoDabAstro from '../../../assets/images/icons/astronaut-dab.svg';
import astroFlag from '../../../assets/images/icons/webfuze-astronaut-flag.svg';
import Category from './Category';
import CustomerReviewsSlider from '../../Shared/CustomerReviewsSlider';
import CustomersSlider from '../../Shared/CustomersSliderLogo';
import Button from '../../Shared/Button';
import Faqs from '../../Shared/Faqs';
import SliderTechnologies from './Technologies';
import './Audit.css';

const details = [
  'Chargement des pages optimisé (réduction du nombre de scripts lors du chargement)',
  'Réduction des scripts externes : suppression des modules et scripts non utilisés',
  'Optimisation du chargement des typographies',
  'Mise en place des modules de caches',
  'Compression et regroupement des tous les fichiers CSS, JS, PHP',
  'Configuration avancée du CDN : mise en cache des médias',
  'Optimisation vitesse des plugins',
  'Optimisation vitesse du thème WordPress',
  'Optimisation des dossiers (suppression des fichiers non utilisés, doublons, logs et de caches)',
  'Compression Gzip optimisation (compression des fichiers pour acceptation nouvelle version des navigateurs : Chrome, Firefox, Safari, ...)',
  "Agencement technique des modules pour faciliter l'accès des robots des moteurs de recherches",
  "WordPress révisions : suppression de l'historique de modifications des pages",
  "Mise en place d'un module de préchargement de l'intégralité des pages",
  'Mise en place cache côté serveur',
];

const Audit = () => {
  const [viewAll, setViewAll] = React.useState(false);
  const [app, dispatch] = useAppContext();
  const formatMessage = useLanguage();
  const setModal = useSetAtom(ModalAtom);

  return (
    <WrapperPage
      page="audit"
      title={
        <>
          {formatMessage(`page.audit.title` as LanguageKeys)}
          <br />
          <span className="text-underline-effect">
            {app.data.audit.website}
          </span>
        </>
      }
    >
      <div className="grid grid-cols-12 md:gap-10 mt-6 lg:mt-12 xl:px-5 2xl:px-20 mb-10">
        <div className="hidden xl:grid col-span-3 grid-flow-row auto-cols-max">
          <div className="w-[2px] bg-blue row-span-4 mx-auto"></div>
          <div className="row-span-1 flex !mt-0">
            <img
              className="lg:h-[40px] xl:h-[60px] my-auto mt-[40px] mb-[15px]"
              src={logoGoogle}
              alt="logo"
            />
          </div>
          <div className="w-[2px] bg-blue row-span-4 mx-auto"></div>
          <div className="row-span-1 flex !mt-0">
            <img
              className="lg:h-[160px] xl:h-[200px] my-auto"
              src={astroAnimFlag}
              alt="logo"
            />
          </div>
          <div className="w-[2px] bg-blue row-span-4 mx-auto"></div>
        </div>

        <div className="col-span-12 xl:col-span-9">
          <Visu
            desktop={app.data.audit.screenshot.desktop_full!}
            mobile={app.data.audit.screenshot.mobile_full!}
          />

          <div className="xl:hidden col-span-12 grid grid-cols-12 md:gap-10 items-center mt-6">
            <div className="h-[2px] w-full col-span-3 bg-blue"></div>
            <div className="col-span-6">
              <img className="w-[140px] m-auto" src={logoGoogle} alt="logo" />
            </div>
            <div className="h-[2px] w-full col-span-3 bg-blue"></div>
          </div>

          <div
            // data-aos="fade"
            className="flex justify-around xl:justify-between xl:px-16 mt-5 lg:px-6"
          >
            <CircleMain
              type="mobile"
              percent={app.data.audit.score?.mobile}
              strokeWidth={6}
              trailWidth={6}
              strokeLinecap="round"
              trailColor="#fff"
              strokeColor="#87d068"
            />
            <CircleMain
              type="desktop"
              percent={app.data.audit.score?.desktop}
              strokeWidth={6}
              trailWidth={6}
              strokeLinecap="round"
              trailColor="#fff"
              strokeColor="#87d068"
            />
          </div>

          <div className="block mt-6">
            <div className="flex justify-end text-black">
              <span className="block max-w-[240px] text-right text-xs">
                * 4 tests réalisés de votre page d'accueil pour une meilleur
                précision.
              </span>
            </div>
            <p className={`mt-2 ${app.data.audit.valid ? '' : 'hidden'}`}>
              <b>Houston nous avons un problème !</b> La vitesse de votre site
              n'est pas aux normes des standards de <b>Google</b>. Il est temps
              de réagir ! Ci-dessous, un audit complet de votre site web pour
              vous aider à l'améliorer.
            </p>
          </div>

          <div
            className={`block mt-8 bg-blue rounded-lg rounded-tr-sm relative py-5 ${
              app.data.audit.valid ? '' : 'hidden'
            }`}
          >
            <div className="inline-block bg-orange text-md h-[43px] px-8 py-3 text-white rounded-[35px] absolute left-0 -top-[20px]">
              AVEC WEBFUZE
            </div>
            <div className="block px-4 pt-3 text-white text-sm">
              Augmentez vos revenus sans gestion et sans efforts supplémentaires
              grâce à notre programme, nos experts mettent tout en ouvre pour
              vous permettre :
              <br />
              <ul className="mt-2">
                <li>
                  - D'augmenter vos <b>taux de conversions</b>.
                </li>
                <li>
                  - D'augmenter <b>votre visibilité</b> sur Google pour{' '}
                  <b>dépasser vos concurrents</b>.
                </li>

                <li>
                  - D'offrir <b>une meilleure expérience</b> à vos utilisateurs
                  avec <b>un site plus rapide</b>.
                </li>
              </ul>
              <p className="block w-full text-center mt-2 ">
                <span className="text-orange font-bold">
                  “SATISFAIT OU GRATIFIÉ”
                </span>
                <br />
                Vous ne payez que si l'objectif est atteint.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade">
        {(viewAll
          ? app.data.audit.details
          : app.data.audit.details.slice(0, 3)
        ).map((detail, index) => (
          <Category key={index} data={detail} />
        ))}

        {viewAll && <SliderTechnologies />}

        <button
          className={`flex items-center ${
            !viewAll ? '-mt-4' : 'mt-8'
          } mt-8 px-6 py-2 space-x-2 mx-auto transition ease-in duration-200 rounded-full text-white hover:bg-orange hover:text-white bg-blue border-blue focus:outline-none`}
          onClick={() => {
            setViewAll(!viewAll);
          }}
        >
          {!viewAll ? <MdOutlineOpenInFull /> : <MdOutlineCloseFullscreen />}
          <span>{!viewAll ? 'Voir plus' : 'Réduire'}</span>
        </button>
      </div>
      <div className="xl:px-5 2xl:px-20 astronaut-rocket-animationastronaut-rocket-animationmt-8 mb-10 md:my-0 bg-white dark:bg-gray-800 overflow-hidden relative flex-rows md:flex justify-between">
        <h2 className="text-2xl md:hidden font-extrabold text-black dark:text-white text-center mb-3 mt-4">
          <span className="block">En 2 mots WebFuze c'est ?</span>
        </h2>
        <div className="hidden md:block text-start w-5/12 lg:w-6/12 py-12 px-4 !pl-0 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 className="text-2xl font-extrabold text-black dark:text-white sm:text-2xl">
            <span className="block">En 2 mots WebFuze c'est ?</span>
            <span className="block text-orange">3 . 2 . 1...</span>
          </h2>
          <p className="text-md mt-2 text-gray-400">
            Notre mission est d’aider les entreprises et les indépendants à
            vendre plus grâce à un site plus performant.
          </p>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-6 inline-flex rounded-md shadow">
              <Button
                className={`py-4 px-6 hover:bg-blue bg-orange text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg  ${
                  app.data.audit.valid ? '' : 'hidden'
                }`}
                name="auditCta"
                onClick={() =>
                  dispatch({
                    type: AppTypes.SetCalendlyIsOpen,
                    payload: true,
                  })
                }
                children={
                  <span className="text-center text-base font-semibold">
                    Augmenter mon chiffre d'affaire
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div
          className="relative w-full md:w-7/12 lg:w-6/12 right-0 top-0 my-auto rounded-lg cursor-pointer"
          onClick={() =>
            setModal({
              isOpen: true,
              title: 'youtube',
            })
          }
        >
          <img
            src="https://storage.googleapis.com/webfuze-cdn/backgroundVideoWebfuze.jpg"
            className="rounded-lg"
          />
          <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 play-btn max"></button>
        </div>
      </div>
      <div className="xl:px-5 2xl:px-20">
        <CustomersSlider />
      </div>
      <div className="xl:px-5 2xl:px-20 lg:gap-8 xl:gap-0 lg:grid-cols-12 mt-8">
        <div className="lg:flex">
          <div>
            <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
              Le programme{' '}
              <span className="text-underline-effect">webfuze</span>.
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-500 dark:text-gray-200">
              L’adhésion à WebFuze est totalement sans risque grâce à notre
              politique <b>“SATISFAIT OU GRATIFIÉ”</b>. Vous ne payez que si
              votre site atteint l’objectif minimum de 80% sur mobile et 90% sur
              desktop lors du test réaliser sur l'outil PageSpeed de Google.
            </p>
            <div className="mt-5">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 bg-white dark:bg-gray-800 text-sm leading-5 tracking-wider font-semibold uppercase text-orange">
                  Les plus WebFuze
                </h4>
                <div className="flex-1 border-t-2 border-blue"></div>
              </div>
              <ul className="mt-4 grid md:grid-cols-2 gap-2 lg:gap-3">
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Une garantie de 6 mois
                  </p>
                </li>
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Satisfait ou gratifié
                  </p>
                </li>
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Un support téléphonique
                  </p>
                </li>
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Un suivi détaillé mensuel
                  </p>
                </li>
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Un accompagnent personnalisé
                  </p>
                </li>
                <li className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <BsCheckCircleFill className="text-[#10b981] text-[22px]" />
                  </div>
                  <p className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                    Paiement en plusieurs fois
                  </p>
                </li>
              </ul>
              <div className="flex items-center mt-6">
                <h4 className="flex-shrink-0 pr-4 bg-white dark:bg-gray-800 text-sm leading-5 tracking-wider font-semibold uppercase text-orange">
                  Quelques détails du programme
                </h4>
                <div className="flex-1 border-t-2 border-blue"></div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex-col lg:justify-center lg:pl-12 lg:pt-10">
            <img
              src={LogoDabAstro}
              alt="WebFuze"
              className="w-auto h-[153px] m-auto"
            />
            <Button
              className={`w-56 m-auto mt-0 px-3 py-3   shadow border border-blue  rounded-lg text-white bg-blue hover:bg-orange hover:border-orange ${
                app.data.audit.valid ? '' : 'hidden'
              }`}
              name="auditCta"
              onClick={() =>
                dispatch({
                  type: AppTypes.SetCalendlyIsOpen,
                  payload: true,
                })
              }
              children={<span className="text-sm">Développer vos ventes</span>}
            />
          </div>
        </div>

        <ul className="grid lg:grid-cols-2 gap-1 lg:gap-2 mt-4">
          {details.map((detail, i) => (
            <li className="flex items-start lg:col-span-1" key={i}>
              <div className="flex-shrink-0">
                <BsCheck2Circle className="text-xl" />
              </div>
              <div className="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                {detail}
              </div>
            </li>
          ))}
        </ul>

        <div className="lg:hidden text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex-col lg:justify-center lg:pl-12 lg:pt-10">
          <img src={LogoDabAstro} alt="WebFuze" className="w-full h-[153px]" />
          <Button
            className="w-56 m-auto mt-0 px-3 py-3 text-sm font-semibold shadow border border-blue  rounded-lg text-white bg-blue hover:bg-orange hover:border-orange"
            name="auditCta"
            onClick={() =>
              dispatch({
                type: AppTypes.SetCalendlyIsOpen,
                payload: true,
              })
            }
            children={<>Développer vos ventes</>}
          />
        </div>
      </div>
      <CustomerReviewsSlider />

      <Faqs />

      <div
        data-aos="fade"
        className="mt-10 text-center w-full mx-auto px-4 sm:px-6 lg:px-8"
      >
        <img src={astroFlag} alt="WebFuze" className="w-full h-[153px] mb-2" />
        <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
          <span className="block">Pret pour le décollage ?</span>
          <span className="block text-orange text-xl">
            Votre entreprise peut gagner plus d'argent.
          </span>
        </h2>
        <p className="text-xl mt-4 max-w-md mx-auto text-gray-400">
          Nous sommes des experts dans le soutien d’experts comme vous.
        </p>
        <div className="lg:mt-0 lg:flex-shrink-0 mb-8">
          <Button
            name="auditCta"
            onClick={() =>
              dispatch({
                type: AppTypes.SetCalendlyIsOpen,
                payload: true,
              })
            }
            className={`mt-4 ${app.data.audit.valid ? '' : 'hidden'}`}
            children={
              <span className="text-center text-base font-semibold">
                Programmer une démo
              </span>
            }
          />
        </div>
      </div>
    </WrapperPage>
  );
};

export default Audit;
