import React, { createContext } from 'react';
import AllData from '../Hooks/AllData';

export const MyContext = createContext<ReturnType<typeof AllData> | null>(null);

const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const value = AllData();

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};

export default ContextProvider;
