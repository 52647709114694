import { Link } from 'react-router-dom';

import LeftColumnEmployee from './LeftColumnEmployee';
// import LeftColumnMenu from './LeftColumnMenu';
import LeftColumnContact from './LeftColumnContact';

import logoBig from '../../assets/images/logo/logo-full-big.jpg';

const LeftColumn = () => {
  return (
    <div className="w-full mb-6 lg:mb-0 mx-auto relative bg-white drop-shadow-xl text-center dark:bg-[#111111] p-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0">
      <img
        className="hidden lg:block mb-6 h-[155px] lg:h-[123px]"
        src={logoBig}
        alt="logo"
      />

      <LeftColumnContact />

      {/* <LeftColumnMenu /> */}

      <LeftColumnEmployee />
    </div>
  );
};

export default LeftColumn;
