// import { useAppContext } from '../lib/context';
import languages from '../lib/languages.json';

const useLanguage = () => {
  //   const [appSettings] = useAppContext();

  const formatMessage = (
    code: keyof typeof languages.fr,
    params?: { [key: string]: string | number },
  ) => {
    const langage = languages.fr;

    return langage[code];
  };

  return formatMessage;
};

export type LanguageKeys = keyof typeof languages.fr;

export default useLanguage;
