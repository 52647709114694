import { useState } from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import { FcCalendar } from 'react-icons/fc';
import { useLocation } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';

import UseData from '../Hooks/UseData.js';
import logo from '../../assets/images/logo/logo-full-line.jpg';
import { useAppContext, AppTypes } from '../../lib/context';
import Button from '../Shared/Button.js';
import useLanguage from '../../hooks/useLanguage';

export default function Header() {
  const [app, dispatch] = useAppContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleTheme, check, menuItemTwo, NavLink } = UseData();
  const formatMessage = useLanguage();
  const a = useLocation();

  const handle = (e: string) => {
    handleTheme(e);
  };

  return (
    <>
      <div
        className="container w-full bg-[#fff] dark:bg-black lg:bg-transparent lg:dark:bg-transparent flex justify-between py-8 lg:py-10 lg:px-0 lg:pt-[30px] lg:pb-0"
        data-aos="fade"
      >
        <div className="relative px-2 md:px-0 w-full flex justify-between lg:justify-end lg:px-0 items-center">
          {/* website logo */}
          <img className="lg:hidden w-[170px] h-auto" src={logo} alt="logo" />

          <div className="flex justify-end w-full content-center gap-4">
            <Button
              className="hidden md:flex bg-unset border-[#f73a21] border-2 gap-2 items-center"
              type="button"
            >
              <FaPhoneAlt />
              <a href={`tel:${app.data?.user?.phone}`}>
                {app.data?.user?.phone}
              </a>
            </Button>

            <Button
              className={`hidden ${
                app.data.audit.valid ? 'md:block' : 'hidden'
              }`}
              name="header"
              onClick={() => {
                dispatch({
                  type: AppTypes.SetCalendlyIsOpen,
                  payload: true,
                });
              }}
            >
              {formatMessage('button.startProcess')}
            </Button>

            <Button className="lg:hidden" type="button">
              <a href={`tel:${app.data?.user?.phone}`}>
                <FaPhoneAlt />
              </a>
            </Button>

            <Button
              className={`bg-blue ${
                app.data?.audit.valid ? 'lg:hidden' : 'hidden'
              }`}
              type="button"
              onClick={() =>
                dispatch({
                  type: AppTypes.SetCalendlyIsOpen,
                  payload: true,
                })
              }
            >
              <FcCalendar fontSize={25} />
            </Button>

            {/* {!check ? (
                <span
                  onClick={() => handle('dark')}
                  className="bg-white w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full flex justify-center items-center hover:bg-[#ef4060] text-black hover:text-white transition-all duration-300 ease-in-out cursor-pointer ml-3  "
                >
                  <FiMoon className=" text-3xl " />
                </span>
              ) : (
                <span
                  onClick={() => handle('light')}
                  className="bg-[#4D4D4D] w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full flex justify-center items-center hover:bg-[#ef4060] transition-all duration-300 ease-in-out cursor-pointer ml-3  "
                >
                  <FiSun className="text-white text-3xl" />
                </span>
              )} */}

            {/* {!menuOpen ? (
              <span
                onClick={() => setMenuOpen(!menuOpen)}
                className="lg:hidden   bg-blue w-[40px] h-[40px] rounded-full flex justify-center items-center text-white dark:text-white text-3xl"
              >
                <AiOutlineMenu />
              </span>
            ) : (
              <span
                onClick={() => setMenuOpen(!menuOpen)}
                className="lg:opacity-0 lg:invisible visible opacity-100  bg-blue w-[40px] h-[40px] rounded-full flex justify-center items-center text-white text-3xl"
              >
                <AiOutlineClose />
              </span>
            )} */}
          </div>
        </div>
      </div>
      <nav className={`${menuOpen ? 'block lg:hidden' : 'hidden'}`}>
        {/* mobile menu items */}
        <ul
          className={`${
            menuOpen
              ? 'block rounded-b-[20px] shadow-md absolute right-4 md:right-0 top-[90px] z-[11111] w-full bg-white dark:bg-[#1d1d1d] w-2/3'
              : 'flex my-12 '
          }`}
        >
          {menuItemTwo.map((item) => (
            <li onClick={() => setMenuOpen(false)} key={item.id}>
              <NavLink
                key={item.id}
                activeClassName=" text-[#FA5252]  hover:text-[#FA5252] "
                inactiveClassName=" dark:text-white dark:hover:text-[#FA5252]  hover:text-[#FA5252]  "
                className={`${
                  menuOpen ? ' pl-4' : ' mx-2.5 rounded-md '
                }    cursor-pointer  transition-colors duration-300 ease-in-out  font-poppins   text-gray-lite font-medium   flex text-xtiny py-2.5 md:px-4 xl:px-5 items-center  ${
                  (a.pathname === '/' && item.id === '01') ||
                  (a.pathname.includes('works') && item.id === '04') ||
                  (a.pathname.includes('/blog') && item.id === '05')
                    ? 'text-[#FA5252]   '
                    : ''
                }`}
                to={item?.link}
              >
                <span className="mr-2 text-xl">{item?.icon}</span> {item?.name}
              </NavLink>
            </li>
          ))}

          <li
            onClick={() => setMenuOpen(false)}
            className={`bg-orange rounded-bl-xl rounded-br-xl cursor-pointer text-center py-2 ${
              app.data.audit.valid ? '' : 'hidden'
            }`}
          >
            <NavLink className={`text-white font-medium`} to="/offers">
              {formatMessage('button.startProcess')}
            </NavLink>
          </li>

          {/* mobile dark and light mode button */}

          {!check ? (
            <span
              onClick={() => handle('dark')}
              className="bg-white text-black hover:text-white w-[40px] hidden  h-[40px] rounded-full lg:flex justify-center items-center  hover:bg-[#ef4060] transition-all duration-300 ease-in-out cursor-pointer "
            >
              <FiMoon className=" text-3xl " />
            </span>
          ) : (
            <span
              onClick={() => handle('light')}
              className="bg-black w-[40px] h-[40px] hidden  rounded-full lg:flex justify-center items-center   hover:bg-[#ef4060] transition-all duration-300 ease-in-out cursor-pointer "
            >
              <FiSun className="text-white text-3xl" />
            </span>
          )}
        </ul>
      </nav>
    </>
  );
}
