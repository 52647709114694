import { Accordion } from 'flowbite-react';
import {
  BsCheckCircleFill,
  BsFillXCircleFill,
  BsExclamationCircleFill,
} from 'react-icons/bs';
import { Collections } from '@webfuze/shared';

export default function Category({
  data,
}: {
  data: Collections.Audit.AuditItem;
}) {
  return (
    <div className="accordion block mt-3 mb-10">
      <h2 className="text-3xl font-bold">{data.title}</h2>
      <Accordion flush>
        {data.items.map((item, index) => {
          let icon = (
            <BsCheckCircleFill className="text-lighthouseSuccess text-[29px] " />
          );

          if (item.type === 'error')
            icon = (
              <BsFillXCircleFill className="text-lighthouseError text-[29px] " />
            );

          if (item.type === 'warning')
            icon = (
              <BsExclamationCircleFill className="text-lighthouseWarning text-[29px] " />
            );

          return (
            <Accordion.Panel key={index}>
              <Accordion.Title>
                <div className="flex justify-start font-bold space-x-4 text-black">
                  <div className="w-[30px]">{icon}</div>
                  <span className="text-sm lg:text-md my-auto pr-1">
                    {item.title}
                  </span>
                </div>
              </Accordion.Title>
              <Accordion.Content>
                <p className="mb-2 text-gray-500 dark:text-gray-400 text-[14px] lg:px-8">
                  {item.description}
                </p>
              </Accordion.Content>
            </Accordion.Panel>
          );
        })}
      </Accordion>
    </div>
  );
}
