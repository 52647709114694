import React from 'react';

import { useAppContext } from '../../lib/context';
import useLanguage from '../../hooks/useLanguage';
import './Faqs.css';

export default function Faqs() {
  const [app] = useAppContext();
  const formatMessage = useLanguage();

  return (
    <div data-aos="fade" className="xl:px-5 2xl:px-20 mx-auto mt-10">
      <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-4">
        {formatMessage('title.faq')}
      </h2>
      <ul className="flex items-start flex-wrap grid lg:grid-cols-2">
        {app.data.faqs.map((faq, index) => (
          <li className="col-span-1" key={index}>
            <details className="info mb-1 p-2 pointer">
              <summary className="text-lg font-bold leading-6">
                {faq.question}
              </summary>
              <p className="text-base leading-5 text-gray-500">{faq.answer}</p>
            </details>
          </li>
        ))}
      </ul>
    </div>
  );
}
