import { initializeApp } from 'firebase/app';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

export const firebaseApp = initializeApp({
  //   apiKey: process.env.REACT_APP_FB_API_KEY,
  //   authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  //   projectId: process.env.REACT_APP_FB_PROJECT_ID,
  //   storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  //   messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  //   appId: process.env.REACT_APP_FB_APP_ID,
  //   measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  apiKey: 'AIzaSyAn6YNT96UiTMY8hG0Zihh5_wl7CxIYib0',
  authDomain: 'webfuze.firebaseapp.com',
  projectId: 'webfuze',
  storageBucket: 'webfuze.appspot.com',
  messagingSenderId: '477759737853',
  appId: '1:477759737853:web:af05c08c818c2c0f601394',
  measurementId: 'G-4FTRLH4BH0',
});

const functions = getFunctions(firebaseApp, 'europe-west3');
const storage = getStorage(firebaseApp);
const firestore = getFirestore(firebaseApp);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
  //   connectFirestoreEmulator(firestore, 'localhost', 8080);
}

const httpsCallableFunc = <P, V>(name: string, props: P) =>
  httpsCallable<P, V>(functions, name)(props).then((e) => e.data);

export default firebaseApp;

export { firestore, functions, httpsCallableFunc, storage };
