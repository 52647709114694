import React from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Player } from '@lottiefiles/react-lottie-player';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';

import Header from '../../Layout/Header';
import LeftColumn from '../../Layout/LeftColumn';
import Loader from '../../Shared/Loader';

import { useAppContext, AppTypes } from '../../../lib/context';

import backJson from '../../../assets/lotties/back.json';
import YoutubeVideoModal from '../../Shared/YoutubeVideoModal';
import config from '../../../lib/config.json';

const WrapperAudit = () => {
  const [searchParams] = useSearchParams();
  const [app, dispatch] = useAppContext();

  console.log(searchParams.get('utm_source')); // ▶ URLSearchParams {}

  const { id } = useParams();
  const navigate = useNavigate();

  useCalendlyEventListener({
    onEventScheduled: (event) => {
      ReactGA.event('generate_lead', {
        currency: 'USD',
        value: app.data.offers[0].price,
      });
    },
  });

  React.useEffect(() => {
    if (id)
      dispatch({
        type: AppTypes.GetSetting,
        payload: id,
      });
  }, [id]);

  React.useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: config.ga_tracking_id,
        gaOptions: {
          send_page_view: true,
        },
      },
    ]);
  }, []);

  if (app.loading || (!app.data && !app.error)) return <Loader />;

  if (app.error) navigate('/404');

  return (
    <section className="min-h-screen px-2 md:pb-8 w-full">
      <Player
        autoplay
        loop
        src={backJson}
        className="hidden lg:block fixed left-0 bottom-0 min-w-full min-h-full"
        speed={0.3}
      ></Player>

      <Header />

      <div className="container grid grid-cols-12 md:gap-10 justify-between lg:mt-[40px] ">
        <div className="col-span-12 lg:col-span-4 xl:col-span-3 hidden lg:block h-screen sticky top-8">
          <LeftColumn />
        </div>
        <div className="col-span-12 lg:col-span-8 xl:col-span-9">
          <Outlet />
        </div>
      </div>

      <PopupModal
        url={app.data.user.calendlyUrl}
        pageSettings={{
          hideLandingPageDetails: true,
          hideGdprBanner: true,
        }}
        // utm={{}}
        prefill={{
          email: app.data.contact.email,
          firstName: app.data.contact.firstname,
          lastName: app.data.contact.lastname,
          customAnswers: {
            a1: app.data.audit.website,
          },
        }}
        onModalClose={() =>
          dispatch({ type: AppTypes.SetCalendlyIsOpen, payload: false })
        }
        open={app.calendlyIsOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById(config.rootElement)!}
      />

      <YoutubeVideoModal />
    </section>
  );
};

export default WrapperAudit;
