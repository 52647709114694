import React from 'react';

import './Visu.css';

export default function Visu({
  mobile,
  desktop,
}: {
  mobile: string;
  desktop: string;
}) {
  return (
    <div className="service-graphic">
      <div className="screen monitor">
        <div className="content">
          <div className="browser">
            <ul className="btns">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="browser-content">
              <ul className="txt">
                <li>
                  <img src={desktop} style={{ width: '100%' }} />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="base">
          <div className="grey-shadow"></div>
          <div className="foot top"></div>
          <div className="foot bottom"></div>
        </div>
      </div>

      <div className="laptop">
        <div className="screen">
          <ul className="txt">
            <img src={desktop} style={{ width: '100%' }} />
          </ul>
        </div>
        <div className="btm"></div>
      </div>

      <div className="phone">
        <div className="screen">
          <ul className="txt">
            <li>
              <img src={mobile} style={{ width: '100%' }} />
            </li>
          </ul>
        </div>
        <div className="shadow"></div>
      </div>

      <div className="ipad">
        <div className="screen">
          <ul className="txt">
            <li>
              <img src={mobile} style={{ width: '100%' }} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
