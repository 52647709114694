import React from 'react';

import logo from '../../assets/images/logo/logo-small-blue.png';

import config from '../../lib/config.json';

const Footer = () => {
  return (
    <footer className="flex justify-between px-6 items-center bg-[#F8FBFB] rounded-br-xl rounded-bl-xl">
      <p className="text-center py-6 text-black dark:text-color-910 ">
        © Copyright'2023. Tous les droits sont réservés par{' '}
        <a
          className="hover:text-[#FA5252] duration-300 transition"
          href={`${config.website}?utm_source=audit&utm_medium=web&utm_campaign=footer-copyright`}
          target="_blank"
          rel="noopener noreferrer"
        >
          WebFuze
        </a>
        .
      </p>
      <img src={logo} className="h-[22px]" />
    </footer>
  );
};

export default Footer;
