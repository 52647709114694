import PageTitle from '../Shared/PageTitle';
import Footer from '../Layout/Footer';

import { useAppContext } from '../../lib/context';
import useLanguage, { LanguageKeys } from '../../hooks/useLanguage';

import astroDab from '../../assets/images/icons/astro-dab.png';

export default function WrapperPage({
  page,
  title,
  children,
}: {
  page: string;
  children: React.ReactNode;
  title?: React.ReactNode | string;
}) {
  const formatMessage = useLanguage();
  const [app] = useAppContext();

  return (
    <>
      <PageTitle
        title={`${formatMessage(`page.${page}.title.short` as LanguageKeys)} ${
          app.data.audit.website
        }`}
      ></PageTitle>

      <section className="lg:rounded-2xl bg-white dark:bg-[#111111] lg:drop-shadow-xl">
        <div data-aos="fade">
          <div className="lg:pt-8 xl:pt-12 lg:py-8 px-4 sm:px-5 md:px-10 lg:px-8 xl:px-12 relative">
            {/* About page title */}
            {title && (
              <h1 className="text-3xl lg:text-4xl font-bold mb-2">{title}</h1>
            )}

            <div className="hidden xl:block absolute top-[-10px] right-[50px]">
              <img src={astroDab} />
            </div>

            {children}
          </div>

          <Footer />
        </div>
      </section>
    </>
  );
}
