import { ITools, Collections } from '@webfuze/shared';

// ********************* Context ********************* //
export namespace AppContext {
  export interface State {
    loading: boolean;
    error?: unknown;
    data: Collections.Landing.Full;
    calendlyIsOpen: boolean;
  }

  export enum Types {
    SetSetting = 'SET_SETTINGS',
    GetSetting = 'GET_SETTINGS',
    ResetSetting = 'RESET_SETTINGS',
    SetCalendlyIsOpen = 'SET_CALENDLY_IS_OPEN',
  }

  type Payload = {
    [Types.SetSetting]: Partial<State>;
    [Types.ResetSetting]: undefined;
    [Types.GetSetting]: string;
    [Types.SetCalendlyIsOpen]: boolean;
  };

  export type Actions =
    ITools.ActionMap<Payload>[keyof ITools.ActionMap<Payload>];
}
