import * as React from 'react';
import { Circle, ProgressProps } from 'rc-progress';

class CircleMain extends React.Component<
  ProgressProps & { type: 'desktop' | 'mobile' },
  any
> {
  constructor(props: ProgressProps & { type: 'desktop' | 'mobile' }) {
    super(props);

    let color = '#0CCE6B';
    if ((props.percent as number) < 50) {
      color = '#ff4242';
    } else if (
      (props.percent as number) > 50 &&
      (props.percent as number) < 90
    ) {
      color = '#ffa400';
    }

    this.state = {
      percent: 0,
      value: props.percent,
      props,
      color,
    };

    this.increase = this.increase.bind(this);
    this.restart = this.restart.bind(this);
  }

  private tm!: NodeJS.Timeout;

  private tm1!: NodeJS.Timeout;

  componentDidMount() {
    this.increase();

    this.tm1 = setInterval(() => {
      this.restart();
    }, 10000);
  }

  componentWillUnmount(): void {
    clearInterval(this.tm1);
  }

  increase() {
    const { percent } = this.state;
    const newPercent = percent + 1;

    if (newPercent > this.state.value) {
      clearTimeout(this.tm);
      return;
    }

    this.setState({ percent: newPercent });
    this.tm = setTimeout(
      this.increase,
      this.state.props.percent > 50 ? 25 : 45,
    );
  }

  restart() {
    clearTimeout(this.tm);

    this.setState({ percent: 0 }, () => {
      this.increase();
    });
  }

  render() {
    return (
      <div className={`inline-block relative`}>
        <h2 className="text-2xl mx-auto w-fit mb-2">
          Sur {this.state.props.type === 'mobile' ? 'MOBILE' : 'DESKTOP'}
        </h2>
        <div className="relative h-36">
          <Circle
            {...this.state.props}
            strokeColor={this.state.color}
            percent={this.state.percent}
            style={{ ...this.state.props.style, height: '100%', width: '100%' }}
          />
          <div
            className="block absolute transform text-4xl top-0 left-0 bottom-0 right-0 m-auto w-[75px] h-[75px]"
            style={{ color: this.state.color }}
          >
            <div className="mx-auto w-fit">{this.state.percent}</div>
            <span
              className="block w-full h-1"
              style={{ backgroundColor: this.state.color }}
            ></span>
            <div className="mx-auto w-fit">100</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CircleMain;
