import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export default function Button(
  props: ComponentProps<'button'> & { children: React.ReactNode },
): JSX.Element {
  const className = twMerge(
    'bg-[#f73a21] duration-200 transition ease-linear hover:bg-gradient-to-r from-[#f73a21] to-[#EB5641] h-[40px] text-md lg:h-[50px] lg:text-lg px-5 lg:px-8 py-2 lg:py-3 text-white rounded-[35px]',
    props.className,
  );

  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  );
}
